const aboutUs = {
	title: "Science Hill Elevators",
	paragraphs: [
		"Science Hill Elevators Inc. is a fully licensed grain elevator owned and operated by Matthew and Ruth Giesbrecht. As well as running the grain elevator we also farm broiler breeders and cash crop together with our 5 children.",
		"In 2015 our family farm corporation, Science Hill Poultry, pulled a natural gas line down the road and a dryer was put up for our corn. Between 2018 and 2020 a truck scale and several new storage bins were installed. At this time we obtained a grain elevator and dealer licence and began offering our services to the community. Then, in 2022 due to family succession planning, Science Hill Elevators Inc. was incorporated to handle the elevator business.",
		"We are excited to announce a planned expansion to our facilities for the fall of 2023! A new 600 bushel dump pit and 10,000 bushels per hour receiving leg will be going up along with a new dryer and expanding wet holding capacity.",
		"Our goals are to have no lines to wait in so you can get back to the field and keep harvest moving as efficiently as possible, to be able to dry grain at a reasonable price, and to offer a competitive price for your grain.",
		"Services include:  Receiving, Drying, Storing, and Purchasing Corn, Soft Red Winter Wheat, and Soybeans.  Custom Drying and Elevation for Grainbank or for storage back on farm.  ",
		"Marketing services include: Cash Sales, Forward Contracts, and Price Later Contracts.",
	],
	landing: ["We are excited to announce the release of our new site! You can easily view Science Hill Elevators' cash bids, futures, weather and more."],
	emailList: [
		"sciencehillelevators@gmail.com",
	],
	showHomePageContact: true,
	showHomePageMap: false,
};

export default aboutUs;
