import React, { useState, useRef } from "react";
import {
	Route,
	Switch,
	Redirect,
	BrowserRouter as Router,
} from "react-router-dom";

import "./Header.css";

//import pages
import Home from "../../pages/home/Home.jsx";
import CashBids from "../../pages/cashBids/CashBids";
import Updates from "../../pages/updates/Updates";
import SignUp from "../../pages/signUp/SignUp";
import Futures from "../../pages/futures/Futures";
import Weather from "../../pages/weather/Weather";

import Config from "../../config/config";

import SignUpButton from "../button/SignupButton.jsx";
import Footer from "../footer/Footer.jsx";

const navItems =
	typeof Config?.navItems !== "undefined"
		? Config?.navItems?.nav
		: [
			{
				title: "Home",
				link: "/home",
				show: true,
			},
			{
				title: "Cash Bids",
				link: "/cashbids",
				show: true,
			},
			{
				title: "Futures",
				link: "/futures",
				show: true,
			},
			{
				title: "News",
				link: "/updates",
				show: Config?.updates?.show,
			},
			{
				title: "Weather",
				link: "/weather",
				show: true,
			},
			{
				title: "Login",
				link: Config?.appDetails?.webLink,
				show: true,
			},
			{
				title: "Sign Up",
				link: "/signup",
				show: true,
			},
		];

const Header = () => {
	const [navBarColor, setNavBarColor] = useState(false);
	const menuRef = useRef();
	const menuBackdropRef = useRef();

	function openMenu() {
		menuRef.current.classList.toggle("open");
		menuBackdropRef.current.classList.toggle("opacity-0");
		menuBackdropRef.current.classList.toggle("pointer-events-none");
	}

	function changeNavBg() {
		if (Config?.styles?.solidNavbar || window.scrollY > 0) {
			setNavBarColor(true);
		} else {
			setNavBarColor(false);
		}
	}
	window.addEventListener("scroll", changeNavBg);

	return (
		<>
			{process.env.REACT_APP_SHOW_BANNER !== 'false' && (
				<div className="w-full bg-[#1B6832] text-white font-semibold text-center py-2" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_BANNER_TEXT }} />
			)}
			<nav
				className={
					navBarColor
						? `fixed top-0 left-0 w-full mx-auto py-3 px-6 z-50 bg-gray-100 mt-5 ${Config.styles.solidNavbar ? Config.styles.mainColor : 'bg-opacity-50'}`
						: `fixed top-0 left-0 w-full mx-auto py-3 px-6 z-50 mt-5 ${Config.styles.solidNavbar && Config.styles.mainColor}`
				}
			>
				<div className="flex items-center justify-between select-none">
					<a href="/" className="z-20">
						<img
							src={Config.styles.logo}
							alt="logo"
							className={
								Config.styles.isLogoSquared
									? "relative ml-8 h-[9rem] w-34"
									: "relative ml-8 h-35 w-64"
							}
						/>
					</a>
					<div className="hidden text-black text-lg md:flex lg:space-x-6 md:space-x-2 select-none items-center justify-center">
						{navItems
							.filter((item) => {
								return item.show;
							})
							.map((navMenu) => {
								return (
									<a
										key={navMenu.link}
										className="hover:font-bold hover:text-black py-2 duration-300 lg:px-6 md:px-3"
										style={{ color: `${Config.styles.paragraphFontColor || '#000'}` }}
										href={navMenu.link}
									>
										{navMenu.title}
									</a>
								);
							})}
					</div>
					<div className="hidden md:flex">
						{Config.appDetails.SignUpButton && (
							<SignUpButton
								title="Sign Up"
								href="/signup"
								backgroundColor={Config.styles.secondaryVariant}
								textColor={Config.styles.btnTextColor}
							/>
						)}

					</div>
					<div
						ref={menuBackdropRef}
						className="opacity-0 pointer-events-none absolute top-0 left-0 font-bold text-black md:hidden backdrop-blur-sm bg-gray-100 bg-opacity-60 h-screen w-screen flex-col space-y-4 justify-center items-center transition-all flex duration-300"
					>
						{navItems
							.filter((item) => {
								return item.show;
							})
							.map((navMenu) => {
								return (
									<a
										key={navMenu.link}
										className="hover:font-bold hover:text-black py-2 duration-300 lg:px-6 md:px-3"
										style={{ color: `${Config.styles.paragraphFontColor || '#000'}` }}
										href={navMenu.link}
									>
										{navMenu.title}
									</a>
								);
							})}
						<div className="space-x-3 z-10 text-sm absolute bottom-20 font-normal">
							Connected by Grain Discovery
						</div>
					</div>
					<button
						id="menu-btn"
						ref={menuRef}
						onClick={openMenu}
						className="block hamburger md:hidden focus:outline-none"
					>
						<span className="hamburger-top"></span>
						<span className="hamburger-middle"></span>
						<span className="hamburger-bottom"></span>
					</button>
				</div>
			</nav>
			{process.env.REACT_APP_EFT_LINK_SLG === 'true' && (
				<a className="absolute top-10 right-10 m-4 pointer z-50 font-bold" href="https://www.tronia.com/stlawrence">Customer Account Login</a>
			)}
			{/*
            Main layout begins here
      */}
			<div
				style={{
					backgroundColor: Config.styles.backgroundColor,
				}}
			>
				<Router>
					<Switch>
						{Config.appDetails.landingPage === "cashbids" && (
							<Redirect exact from="/" to="cashbids"></Redirect>
						)}
						{Config.appDetails.landingPage === "home" && (
							<Redirect exact from="/" to="home"></Redirect>
						)}
						<Route path="/home" component={Home} />
						<Route path="/cashbids" component={CashBids} />
						<Route path="/futures" component={Futures} />
						{Config.updates.show && (
							<Route path="/updates" component={Updates} />
						)}
						<Route path="/weather" component={Weather} />
						<Route path="/signup" component={SignUp} />
						{(typeof Config.navItems !== "undefined" && Config.navItems.customNavRoute) ?
							Config.navItems.customNavRoute.map((route) => route) : null}
					</Switch>
				</Router>
				<Footer />
			</div>
		</>
	);
};

export default Header;
export { navItems };
