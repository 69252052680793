import React, { useEffect, useState } from "react";
import Config from "../../config/config";
import "./CashBids.css";

import Error from "../../assets/images/icons/file_error.svg";
import Empty from "../../assets/images/icons/empty.svg";

import CashBidTable from "../../components/cashBidTable/CashBidTable";
import Api from "../../services/Api";

import BacresBanner from "../../assets/images/adHoc/bacresCashbidBanner.png";

const CashBids = () => {
	const [offers, setOffers] = useState([]);
	const [commodities, setCommodities] = useState([]);
	const [cme, setCme] = useState([]);
	const [error, setError] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const pullData = async () => {
			//fetch offer data
			setIsLoading(true);
			try {
				const res = await Api.getOffers();
				const commoditiesRes = await Api.getCommodities();
				const cmeRes = await Api.getCme();

				setOffers(res.data._embedded.offers);
				setCommodities(commoditiesRes.data._embedded.commodities);
				setCme(cmeRes.data);

				setIsEmpty(
					res.data._embedded.offers.length === 0 ||
					commoditiesRes.data._embedded.commodities.length === 0 ||
					cmeRes.data.length === 0
				);
			} catch (err) {
				console.log('ERROR', err)
				setError(true);
			}
			setIsLoading(false);
		};

		pullData();
	}, []);

	return (
		<>
			<div className="w-full pt-32 px-6 flex flex-col justify-between">
				{process.env.REACT_APP_SHOW_LIVE_PRICES_LOGIN === "true" && (
					<div className="text-right space-x-1 flex flex-row justify-end" style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
						<p>PLEASE</p>
						<a
							className="font-bold"
							style={{ color: Config.styles.secondaryColor }}
							href={Config.appDetails.webLink}
						>
							LOG IN
						</a>
						<p>TO VIEW LIVE PRICES</p>
					</div>
				)}
				{isLoading && (
					<div className="py-64 tablet:p-64 flex justify-center spinner-container">
						<div className="loading-spinner"></div>
					</div>
				)}
				{!error ? (
					<div>
						<CashBidTable data={{ offers, commodities, cme }} />{" "}
						{process.env.REACT_APP_COMPANY_NAME === "bacresGrain" && (
							<img
								className="pb-16 h-60 mx-auto"
								src={BacresBanner}
								alt="For off-farm and direct ship bids, please create an account."
							></img>
						)}
					</div>
				) : (
					<div className="flex flex-col h-full w-full justify-center items-center px-6 py-32">
						<img className="pb-4" src={Error} alt="Error Occurred"></img>
						<p className="text-center text-gray-600">
							An error has occurred while fetching Cash Bid data. <br></br>
							Please refresh and try again.
						</p>
					</div>
				)}
				{isEmpty && (
					<div className="flex flex-col h-full w-full justify-center items-center px-6 p-32">
						<img className="pb-4" src={Empty} alt="Error Occurred"></img>
						<p className="text-center text-gray-600">
							There are no live cash bid data at this moment.
						</p>
					</div>
				)}
			</div>
		</>
	);
};

export default CashBids;
