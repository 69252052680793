import { Route } from "react-router-dom";
import waterlooCropServices from "./custom-pages/waterlooCropServices";

const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "/futures",
			show: true,
		},
		{
			title: "Waterloo Crop Services",
			link: "/waterlooCropServices",
			show: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
	],
	customNavRoute: [
		<Route key={"waterlooCropServices"} path="/waterlooCropServices" component={waterlooCropServices}></Route>,
	],
};

export default navItems;
